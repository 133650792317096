import React from 'react'
import './index.css'
import App from './App'
import { createRoot } from 'react-dom/client'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import { ContextProvider } from './contexts/ContextProvider';

import { reducers } from './reducers';
import { GOOGLE_OAUTH_API } from './constants/actionTypes';

const container = document.getElementById('root')
const root = createRoot(container)
const store = createStore(reducers, compose(applyMiddleware(thunk)));

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_API}>
      {/* <React.StrictMode> */}
        <ContextProvider>
          <App />
        </ContextProvider>
      {/* </React.StrictMode> */}
    </GoogleOAuthProvider>
  </Provider>
);

// ReactDOM.render(<App />, document.getElementById('root'))
