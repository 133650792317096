import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useStateContext } from "../contexts/ContextProvider";
import {
  BUTTON_ICON_COLOR,
  FONT_COLOR,
  PROFILE_SETTING_CREATED,
  PROFILE_SETTING_UPDATED,
  PROFILE_STYLE,
  SETTINGS,
  STYLE_1,
  STYLE_2,
  STYLE_3,
  THEME_COLOR,
  THEME_MODE,
} from "../constants/actionTypes";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { MuiColorInput } from "mui-color-input";
import { Button, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateprofilesetting } from "../actions/profileActions";

const ThemeSettings = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    currentColor,
    setThemeSettings,
    currentFontColor,
    setCurrentFontColor,
    styleValue,
    showSettings,
    setOpenSnackbar,
    setMessageSnackbar,
    setStyleValue,
  } = useStateContext();
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false)
  const dispatch = useDispatch();

  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: theme.palette.primary.main,
    },
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  MyFormControlLabel.propTypes = {
    value: PropTypes.any,
  };

  const handleRadioChange = (e) => {
    if (showSettings) {
      setStyleValue(e.target.value);
    }
  };

  const handleCancel = () => {
    setCurrentColor(localStorage.getItem(THEME_COLOR));
    setCurrentFontColor(localStorage.getItem(FONT_COLOR));
    setCurrentMode(localStorage.getItem(THEME_MODE));
    setStyleValue(localStorage.getItem(PROFILE_STYLE));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(currentColor, currentFontColor, currentMode, styleValue);
    setSubmitButtonLoading(true)
    dispatch(
      updateprofilesetting({
        currentColor,
        currentFontColor,
        currentMode,
        styleValue,
      })
    )
      .then((data) => {
        if (data) {
          // console.log(data);
          if ("message" in data) {
            // console.log(data.message)
            if (
              data.message === PROFILE_SETTING_CREATED ||
              data.message === PROFILE_SETTING_UPDATED
            ) {
              const settings = data.data;
              setMessageSnackbar("Profile setting updated");
              setOpenSnackbar(true);
              dispatch({ type: SETTINGS, data: settings });
              setSubmitButtonLoading(false)
              setThemeSettings(false)
            }
            // else if (data.message === REQUEST_FAILED){
            //   setId(null)
            // } else {
            //   setId(null)
            // }
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div>
      <div className="bg-half-transparent w-screen fixed top-0 right-0 z-[200]">
        <div className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-300">
          <div className="flex justify-between items-center p-4 ml-4">
            <p className="font-semibold text-lg">Settings</p>
            <button
              type="button"
              onClick={() => setThemeSettings(false)}
              style={{ color: "rgb(153, 171, 180)", borderRadius: "40%" }}
              className="text-lg p-2 hover:drop-shadow-xl hover:bg-light-gray"
            >
              {/* <MdOutlineCancel /> */}
              <CloseIcon />
            </button>
          </div>
          <div className="flex-col border-t-1 border-color p-4 ml-4">
            <p className="font-semibold text-xl ">Theme Option</p>
            <div className="mt-4">
              <input
                type="radio"
                id="light"
                name="theme"
                value="Light"
                className="cursor-pointer"
                onChange={(e) => setCurrentMode(e.target.value)}
                checked={currentMode === "Light"}
              />
              <label htmlFor="light" className="ml-2 text-md cursor-pointer">
                Light
              </label>
            </div>
            <div className="mt-2">
              <input
                type="radio"
                id="dark"
                name="theme"
                value="Dark"
                onChange={(e) => setCurrentMode(e.target.value)}
                className="cursor-pointer"
                checked={currentMode === "Dark"}
              />
              <label htmlFor="dark" className="ml-2 text-md cursor-pointer">
                Dark
              </label>
            </div>
          </div>
          <div className="p-4 border-t-1 border-color ml-2">
            <p className="font-semibold text-xl ">Theme Colors</p>
            <div className="flex gap-1">
              <MuiColorInput value={currentColor} onChange={setCurrentColor} />
              {/* {themeColors.map((item, index) => (
                <Tooltip key={index} title={item.name} position="TopCenter">
                  <div
                    className="relative mt-2 cursor-pointer flex gap-2 items-center"
                    key={item.name}
                  >
                    <button
                      type="button"
                      className="h-10 w-10 rounded-full cursor-pointer"
                      style={{ backgroundColor: item.color }}
                      onClick={() => setColor(item.color)}
                    >
                      {item.color === currentColor ? (
                        <CheckIcon className={`text-2xl text-white`} />
                      ) : null}
                    </button>
                  </div>
                </Tooltip>
              ))} */}
            </div>
          </div>
          <div className="p-4 border-t-1 border-color ml-2">
            <p className="font-semibold text-xl ">Font Colors</p>
            <div className="flex gap-1">
              <MuiColorInput
                value={currentFontColor}
                onChange={setCurrentFontColor}
              />
            </div>
          </div>
          <div className="p-4 border-t-1 border-color ml-2">
            <p className="font-semibold text-xl ">Profile Style</p>
            <div className="flex gap-1">
              <form onSubmit={handleSubmit}>
                <RadioGroup
                  name="use-radio-group"
                  onChange={handleRadioChange}
                  value={styleValue}
                >
                  <MyFormControlLabel
                    value={STYLE_1}
                    label="Style 1"
                    control={<Radio />}
                  />
                  <MyFormControlLabel
                    value={STYLE_2}
                    label="Style 2"
                    control={<Radio />}
                  />
                  <MyFormControlLabel
                    value={STYLE_3}
                    label="Style 3"
                    control={<Radio />}
                  />
                </RadioGroup>
                <Tooltip title="Save setting on server">
                  <Button
                    type="submit"
                    sx={{
                      mt: 1,
                      mr: 1,
                      color: BUTTON_ICON_COLOR,
                      borderColor: BUTTON_ICON_COLOR,
                    }}
                    disabled={submitButtonLoading}
                    variant="outlined"
                  >
                    Save Setting
                  </Button>                  
                </Tooltip>
                {submitButtonLoading && (
                  <div className="absolute top-[630px] right-[18%]">
                    <CircularProgress size={24} sx={{ color: BUTTON_ICON_COLOR}} />
                  </div>
                )}
                <Tooltip title="Cancel seleted">
                  <Button
                    onClick={handleCancel}
                    sx={{
                      mt: 1,
                      mr: 1,
                      color: BUTTON_ICON_COLOR,
                      borderColor: BUTTON_ICON_COLOR,
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Tooltip>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeSettings;
