import * as actionType from '../constants/actionTypes';

const profileReducer = (state = { profileData: null }, action) => {
  switch (action.type) {
    case actionType.PROFILE:
      // localStorage.setItem(actionType.PROFILE, JSON.stringify({ ...action?.data }));
      localStorage.setItem(actionType.PROFILE_IMAGE, action?.data?.IMAGE)
      return { ...state, profileData: action.data, loading: false, errors: null };
    case actionType.SETTINGS:
      if(action?.data?.currentColor)
        localStorage.setItem(actionType.THEME_COLOR, action?.data?.currentColor)
      if(action?.data?.currentFontColor)
        localStorage.setItem(actionType.FONT_COLOR, action?.data?.currentFontColor)
      if(action?.data?.currentMode)
        localStorage.setItem(actionType.THEME_MODE, action?.data?.currentMode)
      if(action?.data?.styleValue)
        localStorage.setItem(actionType.PROFILE_STYLE, action?.data?.styleValue)
      return { ...state, profileData: action.data, loading: false, errors: null };
    case actionType.REMOVE_SETTINGS:
      localStorage.removeItem(actionType.THEME_COLOR)
      localStorage.removeItem(actionType.FONT_COLOR)
      localStorage.removeItem(actionType.THEME_MODE)
      localStorage.removeItem(actionType.PROFILE_STYLE)
      return { ...state, profileData: null, loading: false, errors: null };
    case actionType.REMOVE_PROFILE:
      // localStorage.removeItem(actionType.PROFILE);
      return { ...state, profileData: null, loading: false, errors: null };
    default:
      return state;
  }
};

export default profileReducer;