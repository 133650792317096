import axios from 'axios';

// const API = axios.create({ baseURL: 'http://localhost:5000' });
const API = axios.create({ baseURL: 'https://api.backend.sirmyne.com' });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('user')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('user')).data.token}`;
  }
  return req;
});

export const fetchPosts = () => API.get('/posts');
export const createPost = (newPost) => API.post('/posts', newPost);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);

export const signIn = (formData) => API.post('/user/signin', formData);
export const googleSignin = (token) => API.post('/user/googlesignin', token);
export const signUp = (formData) => API.post('/user/create', formData);
export const forgotPassword = (formData) => API.post('/user/forgotpassword', formData);
export const resetPassword = (formData) => API.post('/user/resetpassword', formData);
export const confirmEmail = (email) => API.post('/user/confirmemail', email)

export const editProfile = (profile) => API.post('/profile/update', profile)
export const getProfile = (id) => API.post('/profile/get/own', id)
export const getUserProfile = (id) => API.post('/profile/get/user', id)

export const updateProfileSetting = (setting) => API.post('/profile/setting/update', setting)
