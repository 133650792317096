import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import { useStateContext } from "./contexts/ContextProvider";
import ThemeSettings from "./components/ThemeSettings";
import Snackbar from "@mui/material/Snackbar";
import { Fade, LinearProgress } from "@mui/material";
// import TestPage from "./pages/TestPage";
import Footer from "./components/Footer";

const HomePage = lazy(() => import('./pages/Home'))
const SigninPage = lazy(() => import('./pages/Signin'))
const ProfilePage = lazy(() => import('./pages/Profile'))
const ConfirmEmailPage = lazy(() => import('./pages/ConfirmEmail'))
const ResetPasswordPage = lazy(() => import('./pages/ResetPassword'))
const EditProfilePage = lazy(() => import('./pages/EditProfile'))
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicy'))
const TermAndConditionsPage = lazy(() => import('./pages/TermAndConditions'))

const App = () => {
  const activeMenu = false;
  const {
    themeSettings,
    currentMode,
    openSnackbar,
    messageSnackbar,
    setOpenSnackbar,
    vertical,
    horizontal
  } = useStateContext();

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       // autoDisplay: false
  //     },
  //     "google_translate_element"
  //   );
  // };

  useEffect(() => {
    // var addScript = document.createElement("script");
    // addScript.setAttribute(
    //   "src",
    //   "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    // );
    // document.body.appendChild(addScript);
    // window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  return (
    <div className={currentMode === "Dark" ? "dark" : ""} id="parent">
      <Suspense fallback={<LinearProgress sx={{ width: '100%'}} />}>
        <BrowserRouter>
          <Navbar />
          {/* <div id="google_translate_element" className="hidden"></div> */}
          {themeSettings ? <ThemeSettings /> : null}
          
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/signin" element={<SigninPage />} />
            <Route path="/confirmemail" element={<ConfirmEmailPage />} />
            <Route path="/resetpassword" element={<ResetPasswordPage />} />
            <Route path="/editprofile" element={<EditProfilePage />} />
            {/* <Route path="/testpage" element={<TestPage />} /> */}
            <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
            <Route path="/termsandconditions" element={<TermAndConditionsPage />} />
          </Routes>
        </BrowserRouter>
        <Snackbar
          anchorOrigin={{vertical, horizontal}}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          message={messageSnackbar}
          TransitionComponent={Fade}
        />
        <Footer />
      </Suspense>      
    </div>
  );
};

export default App;
