import * as actionType from '../constants/actionTypes';

const authReducer = (state = { authData: null }, action) => {
  switch (action.type) {
    case actionType.AUTH:
      localStorage.setItem(actionType.USER, JSON.stringify({ ...action?.data }));
      localStorage.setItem(actionType.PROFILE_IMAGE, action?.data?.data?.user?.IMAGE)
      return { ...state, authData: action.data, loading: false, errors: null };
    case actionType.REMOVE_USER:
      localStorage.removeItem(actionType.USER);
      localStorage.removeItem(actionType.PROFILE_IMAGE)
      return { ...state, authData: null, loading: false, errors: null };
    default:
      return state;
  }
};

export default authReducer;