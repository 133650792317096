import * as api from "../api/index.js";
import {
  CREATED_PROFILE,
  PROFILE,
  PROFILE_RETRIEVE,
  REQUEST_FAILED,
  SETTINGS,
  UPDATED_PROFILE,
} from "../constants/actionTypes.js";

export const getprofile = (id, router) => async (dispatch) => {
  try {
    const result = await api.getProfile(id);
    const { data } = result;
    // console.log(result, data)
    if (data) {
      if ("message" in data) {
        // console.log(data.message);
        if (data.message === PROFILE_RETRIEVE) {
          const profile = data.data;
          dispatch({ type: PROFILE, data: profile });
          return profile;
        } else if (data.message === REQUEST_FAILED) {
          router("/signin");
        }
      }
    }
    return data;
  } catch (error) {
    // console.log(error);
  }
};

export const getuserprofile = (id) => async (dispatch) => {
  try {
    const {data} = await api.getUserProfile(id);
    // console.log(data)
    return data;
  } catch (error) {
    // console.log(error);
  }
};

export const editprofile =
  (formData, router, setSubmitButtonLoading) => async (dispatch) => {
    try {
      const { data } = await api.editProfile(formData);
      // console.log(data)
      setSubmitButtonLoading(false);
      if ("message" in data) {
        if (
          data.message === UPDATED_PROFILE ||
          data.message === CREATED_PROFILE
        ) {
          const profile = data.data;
          dispatch({ type: PROFILE, data: profile });
          dispatch({
            type: SETTINGS,
            data: {
              currentColor: profile.THEME_COLOR,
              currentFontColor: profile.FONT_COLOR,
              currentMode: profile.THEME_OPTION,
              styleValue: profile.STYLE,
            },
          });
          if ("message" in data) {
            // console.log(data.message)
            if (
              data.message === UPDATED_PROFILE ||
              data.message === CREATED_PROFILE
            ) {
              router("/profile");
            } else if (data.message === REQUEST_FAILED) {
              router("/signin");
            }
          }
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

export const updateprofilesetting = (settings) => async (dispatch) => {
  try {
    const result = await api.updateProfileSetting(settings);
    const { data } = result;
    // console.log(result, data)
    if (data) {
      return data;
    } else {
      return result;
    }
    // if ("message" in data) {
    //   if (data.message === UPDATED_PROFILE) {
    //     const profile = data.data;
    //     dispatch({ type: PROFILE, data: profile });
    //     if ("message" in data) {
    //       // console.log(data.message)
    //       if (data.message === UPDATED_PROFILE) {
    //         router("/profile");
    //       } else if (data.message === REQUEST_FAILED) {
    //         router("/signin");
    //       }
    //     }
    //   }
    // }
  } catch (error) {
    // console.log(error);
  }
};
