import { Container, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
// import logo from "../images/Sir.png";
// import logo from "../images/logo.png";
import logo from "../images/SirMyneLogo.webp";
import { EmailTwoTone } from "@mui/icons-material";
import LazyLoad from "react-lazyload";
import { DOMAINEMAIL } from "../constants/actionTypes";

const Footer = () => {
  return (
    <div id="footer-div">
      <Container>
        <Grid container sx={{ paddingY: 5 }}>
          <Grid item xs={12} sm={2}>
            <LazyLoad offset={100}>
              <img src={logo} alt="logo" className="w-[120px] mx-auto" />
            </LazyLoad>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="p-1">
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Site Map
              </Typography>
              <a href="/" className="hover:text-yellow-600 mb-10">
                Home
              </a>
              <br />
              {/* <a href='/game' className='hover:text-yellow-600 mb-10' >Game</a><br/> */}
              <a href="/signin" className="hover:text-yellow-600">
                Sign In
              </a>
              <br />
              <a href="/termsandconditions" className="hover:text-yellow-600">
                Terms & Conditions
              </a>
              <br />
              <a href="/privacypolicy" className="hover:text-yellow-600">
                Privacy Policy
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="p-1">
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Contact Us
              </Typography>
              <Tooltip title="Sent Email">
                <a href={`mailto:${DOMAINEMAIL}`}>
                  <EmailTwoTone
                    sx={{ fontSize: 35, paddingRight: 1, color: "#ad7f00" }}
                  />
                  {DOMAINEMAIL}
                </a>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <hr />
        <p className="pb-10 pt-3">
          ©2024{" "}
          <span style={{ color: "#626975" }}>
            <a style={{ color: "#626975" }} href="https://www.sirmyne.com">
              sirmyne.com
            </a>{" "}
          </span>{" "}
          All Rights Reserved.
        </p>
      </Container>
    </div>
  );
};

export default Footer;
