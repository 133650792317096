import React, { createContext, useContext, useState } from 'react';
import { BLACK_COLOR, DEFAULT_COLOR, FONT_COLOR, LIGHT_MODE, PROFILE_IMAGE, PROFILE_STYLE, STYLE_1, THEME_COLOR, THEME_MODE } from '../constants/actionTypes';

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState(localStorage.getItem(THEME_COLOR)??DEFAULT_COLOR);
  const [currentFontColor, setCurrentFontColor] = useState(localStorage.getItem(FONT_COLOR)??BLACK_COLOR);
  const [currentMode, setCurrentMode] = useState(localStorage.getItem(THEME_MODE)??LIGHT_MODE);
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [styleValue, setStyleValue] = useState(localStorage.getItem(PROFILE_STYLE)??STYLE_1)
  const [id, setId] = useState(window.location.href.split('?')[1])
  const [showSettings, setShowSettings] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [messageSnackbar, setMessageSnackbar] = useState('')
  const [profileImg, setProfileImg] = useState(localStorage.getItem(PROFILE_IMAGE)??'')
  const [vertical, setVertical] = useState('bottom')
  const [horizontal, setHorizontal] = useState('left')

  const setMode = (e) => {
    if(showSettings){
      setCurrentMode(e.target.value);
      localStorage.setItem(THEME_MODE, e.target.value);
    }
    
  };

  const setFontColor = (color) => {
    if(showSettings){
      setCurrentFontColor(color)
      localStorage.setItem(FONT_COLOR, color);
    }
    
  }

  const setColor = (color) => {
    if(showSettings){
      setCurrentColor(color);
      // setThemeSettings(false)
      localStorage.setItem(THEME_COLOR, color);
    }
    
  };

  const setStyle = (styleVal) => {
    if(showSettings){
      setStyleValue(styleVal)
      localStorage.setItem(PROFILE_STYLE, styleVal)
    }    
  } 

  const resetMemory = () => {
    setCurrentColor(DEFAULT_COLOR)
    setCurrentFontColor(FONT_COLOR)
    setCurrentMode(LIGHT_MODE)
    setThemeSettings(false)
    setStyleValue(STYLE_1)
    setShowSettings(false)
    setOpenSnackbar(false)
    setMessageSnackbar('')
    setProfileImg('')
  }

  const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider value={{ currentColor, currentMode, activeMenu, screenSize, setScreenSize, handleClick, isClicked, initialState, setIsClicked, setActiveMenu, setCurrentColor, setCurrentMode, setMode, setColor, themeSettings, setThemeSettings, currentFontColor, setCurrentFontColor, setFontColor, styleValue, setStyle, setStyleValue, id, setId, showSettings, setShowSettings, openSnackbar, setOpenSnackbar, messageSnackbar, setMessageSnackbar, profileImg, setProfileImg, vertical, setVertical, horizontal, setHorizontal, resetMemory }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);