import * as React from "react";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
// import AdbIcon from '@mui/icons-material/Adb';
// import logo from "../images/Sir.webp";
// import logo from "../images/logo.png";
import logo from "../images/SirMyneLogo.webp";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { Link } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as actionType from "../constants/actionTypes";
import { jwtDecode } from "jwt-decode";
import { useStateContext } from "../contexts/ContextProvider";
import EditRounded from "@mui/icons-material/EditRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { HomeRounded, LogoutRounded } from "@mui/icons-material";

function Navbar() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const { profileImg, resetMemory } = useStateContext();
  const currentColor = "whitesmoke";

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfile = () => {
    history("/profile");
    setAnchorElUser(null);
  };

  const handleEditProfile = () => {
    history("/editprofile");
    setAnchorElUser(null);
  };

  const handleHome = () => {
    history("/");
    setAnchorElUser(null);
  };

  const logout = () => {
    history("/");
    setAnchorElUser(null);
    dispatch({ type: actionType.REMOVE_USER });
    dispatch({ type: actionType.REMOVE_PROFILE });
    dispatch({ type: actionType.REMOVE_SETTINGS });
    setUser(null);
    resetMemory();
  };

  useEffect(() => {
    const token = user?.data?.token;
    // console.log(token)
    if (token) {
      const decodedToken = jwtDecode(token);
      // console.log(decodedToken)
      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }
    setUser(JSON.parse(localStorage.getItem("user")));
  }, [location]);

  return (
    <AppBar position="static" id="appbar" color="">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Avatar
            alt="logo"
            variant="square"
            src={logo}
            onClick={handleHome}
            sx={{ height: 40, marginRight: 1 }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: currentColor,
              textDecoration: "none",
            }}
          >
            SirMyne
          </Typography>

          {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon sx={{ color: currentColor }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem>
                <Link href="/" underline="none" color={"black"}>
                  <Typography><HomeRounded sx={{ color: actionType.BUTTON_ICON_COLOR, pb: .5}}/> Home</Typography>
                </Link>
              </MenuItem>
              {user ? (
                <MenuItem>
                  <Link href="/profile" underline="none" color={"black"}>
                    <Typography><AccountCircleRoundedIcon sx={{ color: actionType.BUTTON_ICON_COLOR, pb: .5}}/> Profile</Typography>
                  </Link>
                </MenuItem>
              ) : null}
            </Menu>
          </Box> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              justifyContent: "center",
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: currentColor,
              textDecoration: "none",
            }}
          >
            SirMyne
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Link
              underline="none"
              href="/"
              sx={{ my: 2, color: currentColor, display: "block", margin: 2 }}
            >
              <HomeRounded sx={{ pb: 0.5 }} /> Home
            </Link>
            {/* {user ? (
              <Link
                underline="none"
                href="/profile"
                sx={{ my: 2, color: currentColor, display: "block", margin: 2 }}
              >
                <AccountCircleRoundedIcon sx={{ pb: .5 }} /> Profile
              </Link>
            ) : null} */}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {user ? (
              <Tooltip title="Open settings">
                {/* <LazyLoad> */}
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="Profile Img"
                    src={profileImg ?? "/images/avatar/2.jpg"}
                  />
                </IconButton>
                {/* </LazyLoad> */}
              </Tooltip>
            ) : (
              <Tooltip title="Signin or Signup">
                <Link href="/signin">
                  <PersonRoundedIcon
                    sx={{ color: currentColor, marginRight: 5 }}
                  />
                </Link>
              </Tooltip>
            )}
            {user ? (
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleProfile}>
                  <AccountCircleRoundedIcon
                    sx={{ color: actionType.BUTTON_ICON_COLOR, marginRight: 1 }}
                  />{" "}
                  Profile
                </MenuItem>
                <MenuItem onClick={handleEditProfile}>
                  <EditRounded
                    sx={{ color: actionType.BUTTON_ICON_COLOR, marginRight: 1 }}
                  />{" "}
                  Edit Profile
                </MenuItem>
                <MenuItem onClick={logout}>
                  <LogoutRounded
                    sx={{ color: actionType.BUTTON_ICON_COLOR, marginRight: 1 }}
                  />{" "}
                  Logout
                </MenuItem>
                {/* {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))} */}
              </Menu>
            ) : (
              ""
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
